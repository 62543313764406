<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <div class="box_out">
        <van-list
          v-model="loading"
          loading-text=" "
          :finished="finished"
          @load="onLoad"
        >
          <div class="un_finish_box" v-if="un_finish_list.length">
            <div class="record_title">
              进行中的服务({{ un_finish_list.length }})
            </div>
            <div
              class="record_card"
              v-for="(item, index) in un_finish_list"
              :key="index"
              @click="jump_to(item)"
            >
              <div class="card_badge">预约成功</div>
              <div class="card_title">{{ item.department_name }}</div>
              <div class="card_sub">
                <span>{{ item.department }}</span>
                <span>{{ item.doctor_name }}</span>
                <span>{{ format_money(item.price) }}元</span>
              </div>
              <div class="van-hairline--top split_line"></div>
              <div class="card_bottom">
                <div class="card_left">
                  <div class="card_sub">
                    <span>就诊人：</span>
                    <span>{{ item.patient_name }}</span>
                  </div>
                  <div class="card_sub">
                    <span>就诊日期：</span>
                    <span>{{ format_date(item.date) }} {{item.section}}</span>
                  </div>
                </div>
                <div v-if="item.payable" class="card_right" @click.stop="to_pay(item)">去缴费</div>
              </div>
            </div>
          </div>

          <div class="finish_box" v-if="finish_list.length">
            <div class="record_title">往期服务({{ finish_list.length }})</div>
            <div
              class="record_card"
              v-for="(item, index) in finish_list"
              :key="index"
              @click="jump_to(item)"
            >
              <div
                class="card_badge"
                :class="!item.status ? 'done' : 'cancel'"
              >
                {{ !item.status ? "已完成" : "已取消" }}
              </div>
              <div class="card_title">{{ item.department_name }}</div>
              <div class="card_sub">
                <!-- <span>{{ item.department }}</span> -->
                <span>{{ item.doctor_name }}</span>
                <span>{{ format_money(item.price) }}元</span>
              </div>
              <div class="van-hairline--top split_line"></div>
              <div class="card_bottom">
                <div class="card_left">
                  <div class="card_sub">
                    <span>就诊人：</span>
                    <span>{{ item.patient_name }}</span>
                  </div>
                  <div class="card_sub">
                    <span>就诊日期：</span>
                    <span>{{ format_date(item.date) }}</span>
                  </div>
                </div>
                <!-- <div class="card_right" @click.stop="order_again">
                  再次预约
                </div> -->
              </div>
            </div>
          </div>
          <div class="no-data" v-if="!un_finish_list.length&&!finish_list.length&&!loading">
            <img  width="150" src="../../assets/img/no-data.png"/>
              暂无数据
          </div>
        </van-list>
        
      </div>
      
      <router-link
        :to="{
          path: '/select-institutions',
        }"
      >
        <div class="bottom_btn">去挂号</div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { record, pay_info } from "./service";
import { format_money, format_date } from "../../utils/format";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      loading: false,
      list: [],
      un_finish_list: [],
      finish_list: [],
      finished: false,
      base_data: {
        page: -1,
        page_size: 5,
      },
      error: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    
  },
  destroyed() {},
  methods: {
    ...mapMutations("hospital", ["updateOrderPage"]),
    format_money,
    format_date,
    onLoad() {
      this.base_data.page++;
      this.get_list();
    },
    async get_list() {
      this.loading = true;
      try {
        const { data } = await record(this.base_data);
        this.list = this.list.concat(data.processing||[]).concat(data.histories||[]);
        this.un_finish_list = data.processing || []
        this.finish_list = data.histories || []
        if (!this.list.length || this.list.length <= data.processing.length + data.histories.length || this.error) { //data.total_elements
          this.finished = true;
        }
      } finally {
        this.loading = false;
      }
    },
    jump_to(item) {
      this.updateOrderPage(item)
      this.$router.push({
        path: "registration-detail",
      });
    },
    async to_pay(item) {
      this.loading = true
      try {
        const {data} = await pay_info({
          order_no: item.order_no
        })
        if(data.content.payUrl) {
          window.open(data.content.payUrl, '_blank')
        }
      } catch(e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    order_again() {
      this.$router.push({
        path: "select-institutions",
      });
    },
  },
};
</script>
<style scoped>
.g_main_content {
  background: #f2f7f8;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
}

.record_title {
  padding-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #242938;
}

.record_card {
  background: #fff;
  padding: 16px 10px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-top: 16px;
}

.card_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #3d3d3d;
  margin-bottom: 4px;
}

.card_sub {
  font-size: 12px;
  line-height: 17px;
  color: #808080;
  padding-bottom: 4px;

  & span {
    margin-right: 5px;
  }
}

.split_line {
  height: 1px;
  margin: 12px 0;
}

.card_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_right {
  min-width: 80px;
  height: 32px;
  border: 1px solid #1890ff;
  opacity: 0.8;
  border-radius: 19px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 32px;
  color: #1890ff;
  text-align: center;
}

.card_badge {
  width: 68px;
  height: 29px;
  background: #ffa700;
  font-size: 12px;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 10px;

  &.done {
    background: #00d59b;
  }

  &.cancel {
    background: #ebedf0;
    color: #9c9c9c;
  }
}

.box_out {
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding-bottom: 10px;
}

.bottom_btn {
  height: 44px;
  background: #1890ff;
  border-radius: 22px;
  font-size: 14px;
  line-height: 44px;
  color: #ffffff;
  text-align: center;
  margin-top: 12px;
}

.no-data {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #565656;
  & img {
    margin-bottom: 10px;
  }
}
</style>
