import api from '../../utils/api'

// 授权
export async function record (payload) {
  console.log(payload)
  
  return api.get(`/api/v1/mp/register/records`, {params:payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}

export async function pay_info (payload) {
  console.log(payload)
  
  return api.get(`/api/v1/mp/register/payment/self`, {params:payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}







